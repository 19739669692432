<template>
  <PageContainer>
    <div class="layout">
      <resize-wrap class="flex-1">
        <BaseTable
          id="padInfo"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :seq-config="{
            startIndex: (tablePage.currentPage - 1) * tablePage.pageSize,
          }"
          :align="allAlign"
          :data="tableData"
        >
          <template #buttons>
            <el-button
              type="primary"
              v-auth="'分类数据_新增'"
              icon="el-icon-circle-plus-outline"
              @click="
                dialogEdit = true;
                addRow();
              "
            >新增
            </el-button>
          </template>
          <vxe-table-column type="seq" title="序号" width="60" />
          <vxe-table-column field="itemOrder" title="分类排序" min-width="60" show-overflow="title" show-header-overflow="title"/>
          <vxe-table-column field="itemCode" title="分类代码" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="itemName0" title="分类名称0" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="itemName1" title="分类名称1" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="opertime" title="操作时间" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="regtime" title="新增时间" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column field="remark" title="备注" show-overflow="title" min-width="80" show-header-overflow="title"/>
          <vxe-table-column title="操作" min-width="200">
            <template v-slot="{ row }">
              <el-button type="primary" icon="el-icon-edit" circle size="mini" @click="editRow(row)" v-auth="'分类数据_修改'" title="修改"/>
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteRow(row)" v-auth="'分类数据_删除'" title="删除"/>
            </template>
          </vxe-table-column>
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="[
          'Sizes',
          'PrevPage',
          'JumpNumber',
          'NextPage',
          'FullJump',
          'Total',
        ]"
        @page-change="handlePageChange"
      />
      <el-dialog
        :title="dialogEditTitle"
        :visible.sync="dialogEdit"
        :v-if="dialogEdit"
        :append-to-body="true"
        width="850px"
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :inline="true"
          ref="addForm"
          size="mini"
          label-position="right"
          label-width="160px"
          :model="itemEditModel"
          style="margin-top: 20px"
        >
          <el-form-item label="分类代码:" >
            <el-input v-model="itemEditModel.itemCode" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="分类名称:">
            <el-input v-model="itemEditModel.itemName" style="width: 180px"></el-input>
          </el-form-item>
          <el-form-item label="分类名称0:">
            <el-input v-model="itemEditModel.itemName0" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="分类名称1:">
            <el-input v-model="itemEditModel.itemName1" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="分类排序:">
            <el-input v-model="itemEditModel.itemOrder" style="width: 180px">
            </el-input>
          </el-form-item>
          <el-form-item label="备注:">
            <el-input v-model="itemEditModel.remark" style="width: 180px">
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogEdit = false" size="mini">取消</el-button>
          <el-button type="primary" @click="onSubmitBusEdit()" size="mini"
          >保存</el-button
          >
        </div>
      </el-dialog>
    </div>
  </PageContainer>
</template>

<script>
import { getComps } from '@/apis/common'
import {
  gianListItemcs,
  setItemcs,
  deleteItemcs
} from '@/apis/authManage/itemManage'
import XEUtils from 'xe-utils'

export default {
  name: 'pad-info',
  data () {
    return {
      itemEditModel: {
        itemCode: '',
        itemId: '',
        itemName: '',
        itemName0: '',
        itemName1: '',
        itemOrder: 0,
        remark: ''
      },
      rules: {
        compId: [
          { required: true, message: '企业名称为必填选项', trigger: 'change' }
        ]
      },
      dialogEditTitle: '新增分类数据',
      dialogEdit: false,
      searchModel: {
      },
      searchText: '查询',
      loading: false,
      tableData: [],
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: [
          'Sizes',
          'PrevJump',
          'PrevPage',
          'Number',
          'NextPage',
          'NextJump',
          'FullJump',
          'Total'
        ],
        perfect: true
      },
      companyList: [],
      compListOptions: {},
      qrCodeContent: '',
      codeVisible: false
    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    refreshData () {
      this.loading = true
      gianListItemcs({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      })
        .then((res) => {
          this.loading = false
          if (res.code == 200) {
            const data = res.data
            this.tableData = data.rows
            this.tablePage.total = data.totalSize
          } else {
            this.tableData = []
            this.tablePage.total = 0
          }
        })
        .catch((error) => {
          console.log(error)
          //  this.logining = false;
        })
    },
    addRow () {
      this.dialogEditTitle = '新增分类数据'
      this.itemEditModel = {
        itemCode: '',
        itemId: '',
        itemName: '',
        itemName0: '',
        itemName1: '',
        itemOrder: 0,
        remark: ''
      }
    },
    editRow (row) {
      this.dialogEditTitle = '修改分类数据'
      const rowData = {}
      XEUtils.objectEach(this.itemEditModel, (item, key) => {
        rowData[key] = row[key] ? row[key].toString() : ''
      })
      this.itemEditModel = Object.assign({}, rowData)
      this.dialogEdit = true
    },
    deleteRow (row) {
      this.$confirm('确定删除该分类?', '删除分类数据', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        deleteItemcs({
          ids: [row.tabletId]
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功！')
            this.refreshData()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    submitSearch () {
      this.loading = true
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    handleItemChange (val) {},
    onSubmitBusEdit () {
      setItemcs({ ...this.itemEditModel }).then((res) => {
        if (res.code === 200) {
          if (this.itemEditModel.itemId != '') {
            this.$message.success('修改成功！')
          } else {
            this.$message.success('添加成功！')
          }
          this.dialogEdit = false
          this.refreshData()
        } else {
          this.$message.error(res.msg)
        }
      })
    }

  },
  mounted () {
    getComps({})
      .then((res) => {
        if (res.code == 200) {
          this.companyList = res.data.rows
          const temp = {}
          this.companyList.forEach((item) => {
            temp[item.compId] = item.compName
          })
          this.compListOptions = temp
        }
        this.refreshData()
      })
      .catch((res) => {
        console.log(res)
      })
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
