import http from '@/utils/http'

// 分类列表
export const gianListItemcs = p => http.post('/mortconc/itemcs/list', p)

// 分类单条
export const getOneItemcs = p => http.post('/mortconc/itemcs/one', p)

// 分类调整(新增或修改)
export const setItemcs = p => http.post('/mortconc/itemcs/set', p)

// 分类删除
export const deleteItemcs = p => http.post('/mortconc/itemcs/del', p)
